import configs from "@/configs";

export default {
  state() {
    return {
      theme: configs.theme,

      logos: {
        home: `${configs.theme?.split(".")[0]}-home.png`,
        appBar: `${configs.theme?.split(".")[0]}-app-bar.png`,
      },

      bg: 1,
      bgQty: 4,
      lastBg: 1,
      secondLastBg: 2,
      thirdLastBg: 3,
      fourthLastBg: 4,
    };
  },

  mutations: {
    setBg(state, payload) {
      state.bg = payload;
    },
  },

  getters: {
    getTheme: (state) => state.theme,
    getLogos: (state) => state.logos,
    getBg: (state) => state.bg,
    getLastBg: (state) => state.lastBg,
    getSecondLastBg: (state) => state.secondLastBg,
    getThirdLastBg: (state) => state.thirdLastBg,
    getFourthLastBg: (state) => state.fourthLastBg,
  },

  actions: {
    randomBg({ state, commit }) {
      commit("setBg", Math.round(Math.random() * (state.bgQty - 1) + 1));
    },

    nextBg({ state, commit }) {
      if (state.bg === state.bgQty) {
        commit("setBg", 1);
      } else {
        commit("setBg", state.bg + 1);
      }
    },

    previousBg({ state, commit }) {
      if (state.bg === 1) {
        commit("setBg", state.bgQty);
      } else {
        commit("setBg", state.bg - 1);
      }
    },

    removeBg({ commit }) {
      commit("setBg", 0);
    },
  },
};
